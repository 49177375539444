import React from 'react';
import { useNavigate } from "react-router-dom";

const ServiceCards = ({ title, description, imageUrl,redirectTo }) => {
    const navigate = useNavigate();

  return (
    <div className="card" style={{  backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',backgroundImage: `url(${require(`../../../assets/Services/${imageUrl}`)})` }}
        onClick={() => { navigate(redirectTo); }}>
      <div className="content">
        <div className="service-card-title"> {title} </div>
        {/* <p className="service-card-info-description"> {description} </p> */}
      </div>
    </div>
  );
};

export default ServiceCards;

import React, { useEffect } from "react";
import "./App.css";
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom"
import AerialFilmingPage from "./pages/AerialFilmingPage";
import ConstructionInspectionPage from "./pages/ConstructionInspectionPage";
import AgricultureservicesPage from "./pages/AgricultureservicesPage";
import LandingPage from "./pages/LandingPage";
import AerialSurveyAndMapping from "./pages/AerialSurveyAndMapping";
import RoofTopInspectionPage from "./pages/RoofTopInspectionPage";
import IndustrialInspectionPage from "./pages/IndustrialInspectionPage";
import ContactUsIcon from "./components/ContactUsIcon";
import PageNotFound from "./pages/PageNotFound";

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page on route change
  }, [pathname]);

  return null;
};

const App = () => {
  

  return (
    <BrowserRouter>
    <ScrollToTop />
      <Routes>
      
       <Route path="/" element={<LandingPage />} />
       <Route path="/aerial-filming_&_photography" element={<AerialFilmingPage />} />
       <Route path="/construction-inspection" element={<ConstructionInspectionPage />} />
       <Route path="/agriculture-services" element={<AgricultureservicesPage />} />
       <Route path="/aerial-survey-and-mapping" element={<AerialSurveyAndMapping/>} />
       <Route path="/rooftop-inspection" element={<RoofTopInspectionPage />} />
       <Route path="/industrial-inspection" element={<IndustrialInspectionPage />} />
      


        {/* <Route path="/login" element={<LoginPage />} />
        <Route path="/signup" element={<SignupPage />} />
        <Route path="/forgot" element={<ForgetPasswordPage />} />
    
        <Route path="/" element={<MainLayout activePath={activePath} />} >
            <Route index element={<DashboardPage setActivePath={setActivePath} />} />
            <Route path="/inventory" element={<InventoryPage setActivePath={setActivePath} />} />
            <Route path="/cart" element={<CollectionsPage setActivePath={setActivePath} />} />
            <Route path="/donations" element={<DonationsPage setActivePath={setActivePath} />} />
            <Route path="/add/food" element={<AddNewFooodPage setActivePath={setActivePath}/>} />
            <Route path="/notifications" element={<NotificationPage setActivePath={setActivePath}/>} />
            <Route path="/profile" element={<UserProfilePage setActivePath={setActivePath}/>} />
        </Route>

        <Route path="/admin" element={<AdminScreenLayout activePath={activePath} />} >
            <Route index element={<AdminDashboard setActivePath={setActivePath} /> } />
        </Route>  
        
        {/* <Route path="/view/collection/:collectionid" element={<CollectionHomePage />} /> */}
        <Route
  path="*"
  element={
    <PageNotFound/>
  }
/>
      </Routes><ContactUsIcon/>
    </BrowserRouter>
    
  );
};

export default App;
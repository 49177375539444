import { quickLinks, socialMedia, address } from '../../consts/FooterData';
import './style.css'
import Logo from '../../assets/logo.png';
import GooglemapImage from '../../assets/GooglemapImage.png';
import { MdMailOutline, MdPhone, MdLocationOn } from "react-icons/md";

const Footer = () => {
  let iconStyles = { color: "white" };
  return (
    <div className='black-footer-background'>
      <div className='footer-section'>
              <div className="footer-left">
                    <img
                      src={Logo}
                      alt='logo'
                      className="footer-logo"
                    />
                    <div className="footer-paragraph">
                      Connecting Possibilities, Empowering Future
                    </div>
                    <br/>
                    <a href="https://droneservices.m2r2.co.uk/sitemap.xml" className="link">Sitemaps</a>
              </div>

              <div className="footer-links">
                <div className="quick-links-header">Quick Links</div>
                <div className='nav-list'>
                  {quickLinks.map((nav, i) => (
                    <div
                      key={nav.id}
                      className={`nav-item ${
                        i === quickLinks.length - 1 ? 'last-item' : ''
                      }`}
                    >
                      <a href={`#${nav.id}`} className="link">{nav.title}</a>
                    </div>
                  ))}
                </div>
              </div>

              <div className="footer-address">
                  <div className="quick-links-header">Contact Us</div>
                  <div className="uk-india-offices">
                    {address.map((contact) => (
                      <div key={contact.id} className='MapandAddress'>
                        <div className='Address'>
              
                          <div className="social-logo"> <MdMailOutline style={iconStyles}/> <div> {contact.mail}</div></div>
                          <div className="social-logo"><MdPhone style={iconStyles}/> <div> {contact.phone}</div></div>
                          <div className="social-logo-two-line"><MdLocationOn style={iconStyles}/> <div> {contact.location}</div></div>
                        </div>
                      </div>
                    ))}
                </div>
                </div>

                <div className='Map-image'>
                  <a href='https://maps.app.goo.gl/E94dAnACuy7trQnp9' target='_blank' rel="noopener noreferrer">
                    <img src={GooglemapImage} alt="GoogleMap" width={350} className='map-image-context' />
                  </a>
                </div>
     
    </div>      

    <section className="footer-section">
        <div className="footer-bottom">
          <p className="footer-copyright">
            {new Date().getFullYear()} M2R2. All Rights Reserved.
          </p>
          <div className="social-icons">
            <div className="footer-social">
              {socialMedia.map((social) => (
                <a key={social.id} href={social.link} target="_blank" rel="noopener noreferrer" className='social-media-small-icon'>
                  <social.icon style={{ fontSize: 18 }}/>
                </a>
              ))}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Footer;

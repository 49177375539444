import React from 'react'
import './style.css'

const ContactUsForm = ({ onClose }) => {

    const sendEmail = () => {
        const email = 'info.droneservices@m2r2.co.uk';
        const subject = encodeURIComponent('Customer support');
        const body = encodeURIComponent(
          `Phone Number: [Enter Phone Number Here]%0D%0AEmail: [Enter Email Here]%0D%0AMessage: [Enter Message Here]`
        );
    
        window.location.href = `mailto:${email}?subject=${subject}&body=${body}`;
      };

  return (
    <div className='centralised-contact-us-form'>
        
       <div className="product-contact-us"> 
       <div className="modal-close-button-container">
       <div className="modal-close" onClick={onClose}>Close</div></div>
            <br/>
                <div className="contactus-title-container">Drop us a Message</div><br/>
                <div className="contactus-icon-container"></div><br/>
        <div className="contact-us-form">
                    <input type="text" id="fname" name="firstname" placeholder="Your Name.." className='product-contact-us-ip'/>
                    <input type="text" id="email" name="email" placeholder="Your Email.." className='product-contact-us-ip'/>
                    <input type="text" id="phone" name="phone" placeholder="Contact Number" className='product-contact-us-ip'/>
                    <textarea id="subject" name="subject" placeholder="Enter Your Message.." className='product-contact-us-ip-text-area'></textarea><br/><br/>
                    <div className="contact-us-btn" onClick={sendEmail}>Get in Touch</div><br/><br/>
                    </div>

            </div>
    </div>
  )
}

export default ContactUsForm

import {
  LinkedIn as LinkedInIcon,
  YouTube as Youtube,
  Instagram as InstagramIcon,
  Facebook as FacebookIcon
} from '@mui/icons-material';

export const quickLinks =[
    {
        id: "Our Story",
        title: "Our Story",
    },
    {
        id: "Expertise",
        title: "Expertise",
    },
    {
        id: "Innovations",
        title: "Innovations",
    },
    {
        id: "Insights",
        title: "Insights",
    },
    {
        id: "Partnerships",
        title: "Partnerships",
    },
    {
        id: "Join Us",
        title: "Join Us",
    },
];


export const socialMedia = [
    {
      id: "social-media-1",
      icon: InstagramIcon,
      link: "https://www.instagram.com/m2r2limited",
    },
    {   
      id: "social-media-2",
      icon: FacebookIcon,
      link: "https://www.facebook.com/M2R2Limited",
    },
    {
      id: "social-media-3",
      icon: Youtube,
      link: "https://www.youtube.com/@M2R2Limited",
    },
    {
      id: "social-media-4",
      icon: LinkedInIcon,
      link: "https://www.linkedin.com/company/m2r2-ltd",
    },
  ];

  export const address =[
    {
        id:"London-office",
        title: "UK Office",
        mail: "info.dronesolutions@m2r2.co.uk",
        phone: "+44-7464698545",
        location: "Suite No. 3.4, Litchurch Plaza, Litchurch Ln, Derby",
        // map: '<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2406.206514409175!2d-1.464318724374542!3d52.908709707379565!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4879f1670dab1711%3A0xc6986b6773acf4fa!2sM2R2%20LTD!5e0!3m2!1sen!2sin!4v1708596897033!5m2!1sen!2sin" width="300" height="200" allowfullscreen="" loading="lazy"></iframe>'
    }
];
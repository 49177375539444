const PortfolioData = [
    {
    portfolioName:"aerialportfolio",
    imageFilenames:['image1.jpg', 'image2.jpg', 'image3.jpeg', 'image4.jpg', 'image5.png', 'image6.jpg', 'image7.jpg', 'image8.jpg', 'image9.jpg', 'image10.jpg', 'image11.jpg', 'image12.jpg','image13.jpg'],
    header:"",
    description:"",
    },
    {
        portfolioName:"agriculturePortfolio",
        imageFilenames:['image1.jpg', 'image2.png', 'image3.png', 'image4.jpg', 'image5.jpg', 'image6.jpg' ],
        header:"Choose Us for  Excellence in Agriculture",
        description:"Empower your agriculture with M2r2’s drone services, where swift response and advanced technology ensure your farm’s prosperity.",
    },
    {
        portfolioName:"constructionPortfolio",
        imageFilenames: ['image1.jpg', 'image2.jpg', 'image3.jpg', 'image4.jpg', 'image5.jpg', 'image6.jpg', 'image7.jpg', 'image8.jpg'],
        header:"Our Commitment to Your Success",
        description:"M2R2 is dedicated to providing services that not only save you time but also protect your bottom line. By reducing the likelihood of rework, we help you maintain your schedule and budget, ensuring your project's success.",
    },
    {
        portfolioName:"aerialsurveyingPortfolio",
        imageFilenames: ['image1.jpg', 'image2.jpg', 'image3.jpeg', 'image4.png', 'image5.jpg', 'image6.jpg', 'image7.png'],
        header:"Redefining Boundaries - Drone Precision for Surveying and Mapping",
        description:"Select our drone surveying and mapping services to bring unparalleled precision and clarity to your projects.",
    },
    {
        portfolioName:"industrialInspectionPortfolio",
        imageFilenames: ['image1.jpg', 'image2.webp', 'image3.webp', 'image4.jpg', 'image5.jpg', 'image6.jpg', 'image7.jpg', 'image8.jpg', 'image9.jpg', 'image10.jpeg' , 'image11.jpg'],
        header:"",
        description:"",
    },
    {
        portfolioName:"rooftopinspectionportfolio",
        imageFilenames: ['image1.jpg', 'image2.jpg', 'image3.jpg', 'image4.jpg', 'image5.jpg', 'image6.jpg', 'image7.jpeg', 'image8.jpg', 'image9.jpg'],
        header:"",
        description:"",
    },
    {
        portfolioName:"homepagePortfolio",
        imageFilenames: ['image1.jpg', 'image2.jpg', 'image3.jpg', 'image4.jpg', 'image5.jpg', 'image6.png', 'image7.jpg', 'image8.jpg', 'image9.jpg', 'image10.jpg', 'image11.jpg', 'image12.jpg', 'image13.jpg', 'image14.jpg', 'image15.jpg', 'image16.webp', 'image17.jpg'],
        header:"Our Aerial Expertise",
        description:"“Our portfolio showcases a diverse range of projects, including aerial filming, rooftop inspection, construction site inspection, agriculture services, and industrial inspections using cutting-edge drone technology.”",
    }
];

export default PortfolioData;
import React from 'react'
import './style.css'
import QuickInfoCards from './QuickInfoCards'

const QuickInfoBanner = ({QuickInfo}) => {
  return (
    <div className='quick-info-container'>
        <div className='main-container'>
        <br/><br/>
            <br/><br/><br/>
            <div className="component-centralised-titles">
                <div className="service-description-title">{QuickInfo.title}</div>
            </div>
            <br/>
            <div className="info-container">
                        {QuickInfo.contents.map((item, index) => (
                        <QuickInfoCards
                        key={index}
                        id={index + 1} // Add 1 to index to start from 1 instead of 0
                        label={item.label}
                        content={item.content}
                        />
                    ))}
            </div>
            </div>
      
    </div>
  )
}

export default QuickInfoBanner

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.portfolio-section{
    align-items: center;
    background-color: black;
}

.image-grid {
    line-height: 0;
    display: inline-block;
    margin-right: auto;
    margin-left: auto;
  }
  
  .image-grid img {
    width: 100%;
    height: auto;
    margin-bottom: 10px;
    object-fit: cover;
    transform-origin: 50% 65%;
    transition: transform 5s, filter 3s ease-in-out;
    filter: brightness(150%);
  }

  .image-grid img:hover {
    filter: brightness(100%);
    transform: scale(1.2);
    z-index: 2;
  }
  
  @media screen and (min-width: 768px) {
    .image-grid {
      column-count: 3;
      column-gap: 10px;
    }
  }
  
  @media screen and (max-width: 767px) {
    .image-grid {
      column-count: 1;
    }
  }
  
`, "",{"version":3,"sources":["webpack://./src/components/ServiceComponents/Portfolio/style.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,uBAAuB;AAC3B;;AAEA;IACI,cAAc;IACd,qBAAqB;IACrB,kBAAkB;IAClB,iBAAiB;EACnB;;EAEA;IACE,WAAW;IACX,YAAY;IACZ,mBAAmB;IACnB,iBAAiB;IACjB,yBAAyB;IACzB,+CAA+C;IAC/C,wBAAwB;EAC1B;;EAEA;IACE,wBAAwB;IACxB,qBAAqB;IACrB,UAAU;EACZ;;EAEA;IACE;MAGE,eAAe;MAGf,gBAAgB;IAClB;EACF;;EAEA;IACE;MAGE,eAAe;IACjB;EACF","sourcesContent":[".portfolio-section{\n    align-items: center;\n    background-color: black;\n}\n\n.image-grid {\n    line-height: 0;\n    display: inline-block;\n    margin-right: auto;\n    margin-left: auto;\n  }\n  \n  .image-grid img {\n    width: 100%;\n    height: auto;\n    margin-bottom: 10px;\n    object-fit: cover;\n    transform-origin: 50% 65%;\n    transition: transform 5s, filter 3s ease-in-out;\n    filter: brightness(150%);\n  }\n\n  .image-grid img:hover {\n    filter: brightness(100%);\n    transform: scale(1.2);\n    z-index: 2;\n  }\n  \n  @media screen and (min-width: 768px) {\n    .image-grid {\n      -webkit-column-count: 3;\n      -moz-column-count: 3;\n      column-count: 3;\n      -webkit-column-gap: 10px;\n      -moz-column-gap: 10px;\n      column-gap: 10px;\n    }\n  }\n  \n  @media screen and (max-width: 767px) {\n    .image-grid {\n      -webkit-column-count: 1;\n      -moz-column-count: 1;\n      column-count: 1;\n    }\n  }\n  \n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;


const ServiceDemographicsInfo = [
    {
      image:"service1-bg.jpg",
      title: "Aerial Filming & Photography",
      content:
        "“Capture the extraordinary with our professional drone photography and videography services“",
    },
    {
      image:"service2-bg.jpg",
      title: "Construction Site Inspection",
      content:
      "“Revolutionize Your Construction Projects with Drone Inspections“",
    },
    {
      image:"service3-bg.jpg",
      title: "Agriculture Services",
      content:
        "“Optimize your crop management with our precision agriculture drone services“",
    },
    {
      image:"service4-bg.jpg",
      title: "Aerial Surveying & Mapping",
      content:
        "“Unlock precise insights with with our professional drone aerial surveying and mapping service.” ",
    },
    {
      image:"service5-bg.jpg",
      title: "Industrial Inspection",
      content:
        "“Ensure Industrial safety and compliance with our professional drone inspection service.”",
    },
    {
      image:"service6-bg.jpg",
      title: "RoofTop Inspection",
      content:
        "“Elevate your properties safety and efficiency with our professional rooftop inspection services”",
    },
  ];

  export default ServiceDemographicsInfo;
const AerialServiceDescriptionHeader = 
    {
        title: "Elevate Your Visual Storytelling With Us",
        description:
        "At M2R2, we don't just capture footage; we create visual narratives that soar above the ordinary. Our passion lies in the skies, and our drones are the brushstrokes that paint breath taking canvases. Here’s our services;",
        subTitle:null,
        
    };

const AgricultureServiceDescriptionHeader = 
{
    title: "Elevate Your Farming Efficiency with Precision Drone Services",
    description:
    "Welcome to the future of farming, where technology meets tradition to create a harmonious blend of productivity and sustainability. Our drone services are at the forefront of agricultural innovation, offering precise crop monitoring and effective spraying solutions.",
    subTitle: "Our Specialities are;",
    
};

const ConstructionServiceDescriptionHeader = 
{
    title: "Enhance construction site safety and progress tracking with our professional drone inspection services.",
    description:
    "At M2R2, we understand that time is of the essence in the construction industry. Delays can be costly, and unexpected rework can derail even the best-laid plans. That’s where our drone inspection services come into play, offering a swift and efficient solution to traditional inspection methods.",
    subTitle:"Why Choose Drones for Construction Site Inspections?",
    
};

const aerialSurveyingDescriptionHeader = 
{
    title: "Elevate Your Data with High-Resolution Aerial Imagery",
    description:
    "At M2r2, we specialize in providing comprehensive aerial surveying and mapping services that deliver unparalleled accuracy and detail. Our fleet of advanced drones is equipped with the latest imaging technology to capture high-resolution data that can transform the way you manage land and resources. Our specialities are;",
    subTitle:null,
    
};

const industrialInspectionDescriptionHeader = 
{
    title: "Precision from Above: Ensuring the Integrity of Your Infrastructure",
    description:
    "At M2R2, we specialize in providing top-tier industrial drone inspection services that cater to the critical infrastructure of wind turbines, power lines, and solar panels. Our cutting-edge drones equipped with advanced imaging technology deliver unparalleled insights and data accuracy, ensuring the safety, longevity, and optimal performance of your assets. Here’s our services;",
    subTitle:null,
    
};

const roofTopInspectionDescriptionHeader = 
{
    title: "Elevate Your Roof Inspections with Cutting-Edge Drone Technology",
    description:
    "At M2R2, we specialize in providing comprehensive drone roof top inspection services that deliver precision, efficiency, and safety. Our state-of-the-art drones equipped with high-resolution cameras offer a bird’s-eye view of your property, capturing detailed images that reveal the true condition of your roof.",
    subTitle:null,
    
};

export { AerialServiceDescriptionHeader, AgricultureServiceDescriptionHeader , ConstructionServiceDescriptionHeader, aerialSurveyingDescriptionHeader,industrialInspectionDescriptionHeader,roofTopInspectionDescriptionHeader};
export default AerialServiceDescriptionHeader;  

import React, { useState, useEffect } from 'react';
import './style.css'
import { useNavigate } from "react-router-dom";
import Box from '@mui/joy/Box';
import Drawer from '@mui/joy/Drawer';
import DialogTitle from '@mui/joy/DialogTitle';
import ModalClose from '@mui/joy/ModalClose';
import { Divider, List, ListItem, ListItemButton } from '@mui/joy';

const LandingHeaderComponent = () => {
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);


  const [scrolled, setScrolled] = useState(false);

  
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 150) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  
  // Function to handle smooth scrolling
  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
        window.scrollTo({
            top: section.offsetTop,
            behavior: 'smooth' // Make the scrolling smooth
        });
    }
};

const scrollToAboutSection = () => {
  // Navigate to the homepage
  navigate("/");
  
  // Scroll to the "about" section
  const aboutSection = document.getElementById('about-section');
  if (aboutSection) {
    aboutSection.scrollIntoView({ behavior: "smooth" });
  }
};


  return (
    <div id="header" className={`header ${scrolled ? 'visible' : ''}`}>
        <div className="main-container">
            <div className="header-blocks">
                <div className="header-block-1" onClick={() => { navigate("/"); }}></div>
                <div className="header-block-2">
                  <div className="header-action-button" onClick={scrollToAboutSection}>About</div>
                  <div class="dropdown">
                      <div className="header-action-button">Services</div>
                      <div className="dropdown-content">
                          <div className='dropdown-content-text' onClick={() => { navigate("/aerial-filming_&_photography")}}>Aerial Filming & Photography</div>
                          <div className='dropdown-content-text' onClick={() => { navigate("/rooftop-inspection"); }}>Roof Top Inspection</div>
                          <div className='dropdown-content-text' onClick={() => { navigate("/construction-inspection");}}>Construction Site Inspection</div>
                          <div className='dropdown-content-text' onClick={() => { navigate("/aerial-survey-and-mapping"); }}>Aerial Survey and Mapping</div>
                          <div className='dropdown-content-text' onClick={() => { navigate("/agriculture-services"); }}>Agriculture Services</div>
                          <div className='dropdown-content-text' onClick={() => { navigate("/industrial-inspection"); }}>Industrial Inspection</div>
                      </div>
                    </div>
                  <div className="header-action-button" onClick={() => scrollToSection('portfolioSection') }>Portfolio</div>
                  <div className="header-action-button" onClick={() => scrollToSection('contactusSection')}>Contact</div>
                </div>

                <div className="header-block-3" onClick={() => setOpen(true)}>
                  <div className="handburgericon">
                  </div><div className="handburgericon">
                  </div><div className="handburgericon">
                  </div>
                </div></div>

                
                <Drawer
                    color="neutral"
                    invertedColors                      
                    size="md"
                      variant="soft"
                      open={open} onClose={() => setOpen(false)}
                      sx={{
                        '--Drawer-transitionDuration': open ? '0.4s' : '0.2s',
                        '--Drawer-transitionFunction': open
                          ? 'cubic-bezier(0.79,0.14,0.15,0.86)'
                          : 'cubic-bezier(0.77,0,0.18,1)',
                      }}
                    >  <ModalClose />
                    <DialogTitle>M2R2 - Drone services</DialogTitle>
                      <Box role="presentation" sx={{ p: 2 }}>
                        <List>
                      <ListItem key="text">
                      <ListItemButton onClick={scrollToAboutSection}>About</ListItemButton>
                      </ListItem>
                      <ListItem key="text">Services</ListItem>
                      </List>
                  <Divider />
                  <List>
                      <ListItem key="text">
                        <ListItemButton onClick={() => { navigate("/aerial-filming_&_photography"); }}>Aerial Filming & Photography</ListItemButton>
                      </ListItem>
                      <ListItem key="text">
                        <ListItemButton onClick={() => { navigate("/rooftop-inspection"); }}>Roof Top Inspection</ListItemButton>
                      </ListItem>
                      <ListItem key="text">
                        <ListItemButton onClick={() => { navigate("/construction-inspection"); }}>Construction Site Inspection</ListItemButton>
                      </ListItem>
                      <ListItem key="text">
                        <ListItemButton onClick={() => { navigate("/aerial-survey-and-mapping"); }}>Aerial Survey and Mapping</ListItemButton>
                      </ListItem>
                      <ListItem key="text">
                        <ListItemButton onClick={() => { navigate("/agriculture-services"); }}>Agriculture Services</ListItemButton>
                      </ListItem>
                      <ListItem key="text">
                        <ListItemButton onClick={() => { navigate("/industrial-inspection"); }}>Industrial Inspection</ListItemButton>
                      </ListItem>
                     
                  </List>
                  <Divider />
                  <List>
                  <ListItem key="text">
                        <ListItemButton onClick={() => {setOpen(false); scrollToSection('portfolioSection')}}>Portfolio</ListItemButton>
                      </ListItem>
                      <ListItem key="text">
                        <ListItemButton onClick={() => {setOpen(false); scrollToSection('contactusSection')}}>Contact</ListItemButton>
                      </ListItem></List>
               </Box>
                  
                  </Drawer>
                


                
            </div>
        </div>
  )
}

export default LandingHeaderComponent
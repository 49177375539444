// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.quick-info-container{
    align-items: center;
    background-color: black;
    line-height: 1.4em;
    letter-spacing: 1px;
}

.info-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr)); /* Three columns with a minimum width of 300px */
    grid-gap: 20px; /* Gap between grid items */
  }
  
  /* Media query for smaller screen sizes */
  @media screen and (max-width: 700px) {
    .info-container {
      grid-template-columns: repeat(auto-fill, minmax(200px, 1fr)); /* Adjust the column width for smaller screens */
    }
  }
  
  /* Styles for grid items (optional) */
  .quick-info-item {
    padding: 20px;
    text-align: right;
    color:white;
}

.quick-info-item:hover {
    text-align: right;
    color: #fff;
    cursor: pointer;
    box-shadow: rgba(255, 255, 255, 0.2) 0px -18px 50px -10px;
}

  .quick-info-item-header{
    font-size: 26px;
    font-weight: 700;
    line-height: 1.4em;
    letter-spacing: -1px;
  }

  .quick-info-item-description{
    font-size: 18px;
    line-height: 19.5px;
    margin-block-end: 13px;
    margin-block-start: 0px;
    margin-bottom: 13px;
    font-weight: lighter;
    }

 `, "",{"version":3,"sources":["webpack://./src/components/ServiceComponents/QuickInfoBanner/style.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,uBAAuB;IACvB,kBAAkB;IAClB,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,4DAA4D,EAAE,gDAAgD;IAC9G,cAAc,EAAE,2BAA2B;EAC7C;;EAEA,yCAAyC;EACzC;IACE;MACE,4DAA4D,EAAE,gDAAgD;IAChH;EACF;;EAEA,qCAAqC;EACrC;IACE,aAAa;IACb,iBAAiB;IACjB,WAAW;AACf;;AAEA;IACI,iBAAiB;IACjB,WAAW;IACX,eAAe;IACf,yDAAyD;AAC7D;;EAEE;IACE,eAAe;IACf,gBAAgB;IAChB,kBAAkB;IAClB,oBAAoB;EACtB;;EAEA;IACE,eAAe;IACf,mBAAmB;IACnB,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;IACnB,oBAAoB;IACpB","sourcesContent":[".quick-info-container{\n    align-items: center;\n    background-color: black;\n    line-height: 1.4em;\n    letter-spacing: 1px;\n}\n\n.info-container {\n    display: grid;\n    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr)); /* Three columns with a minimum width of 300px */\n    grid-gap: 20px; /* Gap between grid items */\n  }\n  \n  /* Media query for smaller screen sizes */\n  @media screen and (max-width: 700px) {\n    .info-container {\n      grid-template-columns: repeat(auto-fill, minmax(200px, 1fr)); /* Adjust the column width for smaller screens */\n    }\n  }\n  \n  /* Styles for grid items (optional) */\n  .quick-info-item {\n    padding: 20px;\n    text-align: right;\n    color:white;\n}\n\n.quick-info-item:hover {\n    text-align: right;\n    color: #fff;\n    cursor: pointer;\n    box-shadow: rgba(255, 255, 255, 0.2) 0px -18px 50px -10px;\n}\n\n  .quick-info-item-header{\n    font-size: 26px;\n    font-weight: 700;\n    line-height: 1.4em;\n    letter-spacing: -1px;\n  }\n\n  .quick-info-item-description{\n    font-size: 18px;\n    line-height: 19.5px;\n    margin-block-end: 13px;\n    margin-block-start: 0px;\n    margin-bottom: 13px;\n    font-weight: lighter;\n    }\n\n "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import React from 'react'
import LandingHeaderComponent from '../components/LandingHeaderComponent'
import ServiceDemographics from '../components/ServiceComponents/ServiceDemographics'
import ServiceDescriptions from '../components/ServiceComponents/ServiceDescriptions'
import Portfolio from '../components/ServiceComponents/Portfolio/Portfolio'
import NeedAQuote from '../components/ServiceComponents/NeedAQuote'
import PortfolioData from '../consts/PortfolioData'
import { aerialSurveyingDescriptionInfo } from '../consts/ServiceDescriptionInfo'
import { aerialSurveyingDescriptionHeader } from '../consts/ServiceDescriptionHeaders'
import ServiceDemographicsInfo from '../consts/serviceDemographicsInfo'
import {AerialSurveyingAndMappingQuickInfo} from '../consts/QuickInfoData'
import QuickInfoBanner from '../components/ServiceComponents/QuickInfoBanner/QuickInfoBanner'
import Footer from '../components/Footer/Footer'

const AerialSurveyAndMapping = () => {
  return (
    <div><LandingHeaderComponent/>
    <ServiceDemographics backgroundImage={ServiceDemographicsInfo[3].image} title={ServiceDemographicsInfo[3].title} description={ServiceDemographicsInfo[3].content}/>
    <ServiceDescriptions serviceHeader={aerialSurveyingDescriptionHeader} serviceDescription={aerialSurveyingDescriptionInfo} />
    <QuickInfoBanner QuickInfo={AerialSurveyingAndMappingQuickInfo}/>
    <Portfolio portfoliodetails={PortfolioData[3]}/>
    <NeedAQuote/>
    <Footer/>
    </div>
  )
}

export default AerialSurveyAndMapping

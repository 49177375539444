import React from 'react'
import ServiceDemographics from '../components/ServiceComponents/ServiceDemographics'
import PortfolioData from '../consts/PortfolioData'
import NeedAQuote from '../components/ServiceComponents/NeedAQuote'
import LandingHeaderComponent from '../components/LandingHeaderComponent'
import ServiceDescriptions from '../components/ServiceComponents/ServiceDescriptions'
import { AgricultureServiceDescriptionHeader } from '../consts/ServiceDescriptionHeaders'
import ServiceDemographicsInfo from '../consts/serviceDemographicsInfo'
import { agricultureServiceDescriptionInfo } from '../consts/ServiceDescriptionInfo'
import Portfolio from '../components/ServiceComponents/Portfolio/Portfolio'
import Footer from '../components/Footer/Footer'


const AgricultureservicesPage = () => {

  return (
    <div><LandingHeaderComponent/>
    <ServiceDemographics backgroundImage={ServiceDemographicsInfo[2].image} title={ServiceDemographicsInfo[2].title} description={ServiceDemographicsInfo[2].content}/>
    <ServiceDescriptions serviceHeader={AgricultureServiceDescriptionHeader} serviceDescription={agricultureServiceDescriptionInfo} />
    <Portfolio portfoliodetails={PortfolioData[1]}/>
    <NeedAQuote/>
    <Footer/>
    </div>
  )
}

export default AgricultureservicesPage

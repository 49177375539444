import React from 'react'
import ServiceDemographics from '../components/ServiceComponents/ServiceDemographics'
import ServiceDemographicsInfo from '../consts/serviceDemographicsInfo'
import LandingHeaderComponent from '../components/LandingHeaderComponent'
import ServiceDescriptions from '../components/ServiceComponents/ServiceDescriptions'
import QuickInfoBanner from '../components/ServiceComponents/QuickInfoBanner/QuickInfoBanner'
import Portfolio from '../components/ServiceComponents/Portfolio/Portfolio'
import NeedAQuote from '../components/ServiceComponents/NeedAQuote'
import { AerialServiceDescriptionInfo } from '../consts/ServiceDescriptionInfo'
import  AerialServiceDescriptionHeader from '../consts/ServiceDescriptionHeaders'
import PortfolioData from '../consts/PortfolioData'
import AerialViewQuickInfo from '../consts/QuickInfoData'
import Footer from '../components/Footer/Footer'
// import Pricing from '../components/ServiceComponents/Pricing'
// import AerialFilmingAndPhotography from '../consts/PricingDetailsData'
import { Helmet } from 'react-helmet'

const AerialFilmingPage = () => {

  return (
    <div> <Helmet>
    <title>Aerial Filming and Photography</title>
    <meta name="description" content="Aerial Filming and Photography" />
    <meta name="keywords" content="m2r2, drone services united kingdom, Aerial Filming and Photography" />
    <meta name="author" content="Ajas A S, Arun" />
    <meta property="og:title" content="Aerial Filming and Photography" />
    <meta property="og:description" content="Aerial Filming and Photography" />
    <meta property="og:image" content="https://example.com/image.jpg" />
    <meta property="og:url" content="https://www.linkedin.com/company/m2r2-ltd" />
    <meta name="twitter:title" content="Aerial Filming and Photography" />
    <meta name="twitter:description" content="Aerial Filming and Photography" />
    <meta name="twitter:url" content="https://www.linkedin.com/company/m2r2-ltd" />
    <meta name="twitter:card" content="summary_large_image" />
  </Helmet>
    <LandingHeaderComponent/>
    <ServiceDemographics backgroundImage={ServiceDemographicsInfo[0].image} title={ServiceDemographicsInfo[0].title} description={ServiceDemographicsInfo[0].content}/>
    <ServiceDescriptions serviceHeader={AerialServiceDescriptionHeader} serviceDescription={AerialServiceDescriptionInfo}/>
    <QuickInfoBanner QuickInfo={AerialViewQuickInfo}/>
    <Portfolio portfoliodetails={PortfolioData[0]}/>
    {/* <Pricing pricingDetails={AerialFilmingAndPhotography}/> */}
    <NeedAQuote/>
    <Footer/>
    </div>
  )
}

export default AerialFilmingPage

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.component-centralised-titles{
    height: -moz-fit-content;
    height: fit-content;
    width: 100%;
    margin: auto;
}

.mobile-view-spacer{
    display: block;
    height:100px;
}

.blur-container{
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.2);

}

.demographics-title{
    font-size: 55px;
    font-weight: bold;
    color: white;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

.demographics-desc{
    font-size: 24px;
    color: white;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60%;
    margin: auto;
}


@media screen and (max-width: 600px) {
    .demographics-title{
        font-size: 28px;
    }
    .demographics-desc{
        font-size: 18px;
    }
    .mobile-view-spacer{
        display: block;
        height:100px;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/ServiceComponents/ServiceDemographics/style.css"],"names":[],"mappings":"AAAA;IACI,wBAAmB;IAAnB,mBAAmB;IACnB,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,cAAc;IACd,YAAY;AAChB;;AAEA;IACI,YAAY;IACZ,WAAW;IACX,oCAAoC;;AAExC;;AAEA;IACI,eAAe;IACf,iBAAiB;IACjB,YAAY;IACZ,kBAAkB;IAClB,aAAa;IACb,mBAAmB;IACnB,uBAAuB;AAC3B;;AAEA;IACI,eAAe;IACf,YAAY;IACZ,kBAAkB;IAClB,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,UAAU;IACV,YAAY;AAChB;;;AAGA;IACI;QACI,eAAe;IACnB;IACA;QACI,eAAe;IACnB;IACA;QACI,cAAc;QACd,YAAY;IAChB;AACJ","sourcesContent":[".component-centralised-titles{\n    height: fit-content;\n    width: 100%;\n    margin: auto;\n}\n\n.mobile-view-spacer{\n    display: block;\n    height:100px;\n}\n\n.blur-container{\n    height: 100%;\n    width: 100%;\n    background-color: rgba(0, 0, 0, 0.2);\n\n}\n\n.demographics-title{\n    font-size: 55px;\n    font-weight: bold;\n    color: white;\n    text-align: center;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n}\n\n.demographics-desc{\n    font-size: 24px;\n    color: white;\n    text-align: center;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    width: 60%;\n    margin: auto;\n}\n\n\n@media screen and (max-width: 600px) {\n    .demographics-title{\n        font-size: 28px;\n    }\n    .demographics-desc{\n        font-size: 18px;\n    }\n    .mobile-view-spacer{\n        display: block;\n        height:100px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

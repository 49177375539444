
const AerialViewQuickInfo = {
  title:"Why you should choose us:",
  contents:
  [
    {
      label: "Expertise in the Art of Aerial Filming",
      content:
        "Our expertise in aerial filming elevates storytelling to new heights.",
    },
    {
      label: "Cutting-Edge Technology",
      content:
      "We use the latest drones equipped with high-resolution cameras for crystal-clear imagery",
    },
    {
        label: "Fast Turnaround",
        content:
          "We deliver your aerial footage quickly without compromising quality.",
      },
      {
        label: "Customizable Packages",
        content:
          "Tailored solutions to meet your specific project needs and budget.",
      },
      {
        label: "Safety First",
        content:
          "Our pilots are certified, and our drones adhere to strict regulations.",
      },
      {
        label: "Global Reach",
        content:
          "Based in Derby, we operate across the UK and beyond.",
      },
  ]};


  const AerialSurveyingAndMappingQuickInfo ={
    title:"Benefits of drones in surveying and mapping:",
    contents: [
    {
      label: "High Efficiency",
      content:
        "They can cover large areas quickly, significantly reducing the time required for data collection",
    },
    {
      label: "Cost Reduction",
      content:
      "Drones are more economical than traditional surveying methods, as they require fewer personnel and resources",
    },
    {
        label: "Improved Safety",
        content:
          "They allow for the surveying of dangerous or inaccessible areas without risking human safety",
      },
      {
        label: "Enhanced Accuracy",
        content:
          "Drones provide high-resolution images and data, leading to more precise measurements and models",
      },
      {
        label: "Versatility",
        content:
          "They can be used for a wide range of applications, from agricultural assessments to urban planning",
      },
      {
        label: "Up-to-Date Information",
        content:
          "They enable the creation of current and accurate cadastral maps, even in complex environments",
      },
  ]};


  const IndustrialInspectionQuickInfo ={
    title:"Why you choose us:",
    contents: [
    {
      label: "Unmatched Precision",
      content:
        "Our drones capture every detail, ensuring your wind turbines, solar panels, and powerlines are in top condition.",
    },
    {
      label: "Enhanced Safety",
      content:
      "Our drone services prioritize safety, eliminating the need for risky manual inspections. Protect your team with our cutting-edge aerial inspections.",
    },
    {
        label: "Cost Savings",
        content:
          "Early detection of issues means fewer repairs and extended asset life, making our services a smart financial choice.",
      },
      {
        label: "Rapid Turnaround",
        content:
          "Our drones provide quick and thorough inspections, minimizing downtime and getting your operations back up to speed swiftly.",
      },
      {
        label: "Eco-Friendly",
        content:
          "We provide an eco-friendly solution that reduces the environmental impact of traditional inspection methods",
      },
      {
        label: "Data-Driven Decisions",
        content:
          "Our detailed analyses offer insights that drive efficiency and optimize performance.",
      },
  ]};

  const RoofTopInspectionQuickInfo ={
    title:"Why you choose us:",
    contents: [
    {
      label: "Time-Efficient",
      content:
        "Our drone inspections are swift, minimizing disruption to your daily operations",
    },
    {
      label: "Cost-Effective",
      content:
      "Save on the expense of traditional inspection methods while obtaining high-quality results",
    },
    {
        label: "Enhanced Safety",
        content:
          "Drones eliminate the need for personnel to work at dangerous heights, thereby reducing the risk of accidents and ensuring a safer inspection process",
      },
      {
        label: "Detailed Analysis",
        content:
          "Our drones capture high-resolution images that offer more detail than the human eye can see, allowing for a thorough analysis of your roof’s condition",
      },
      {
        label: "Access to Difficult Areas",
        content:
          "Drones can easily reach areas that are otherwise challenging or impossible for humans to access, ensuring complete coverage of your roof",
      },
      {
        label: "Global Reach",
        content:
          "Based in Derby, we operate across the UK and beyond.",
      },
  ]};

 

export { AerialViewQuickInfo, AerialSurveyingAndMappingQuickInfo , IndustrialInspectionQuickInfo, RoofTopInspectionQuickInfo};
export default AerialViewQuickInfo;
import React, { useState } from 'react';
import './style.css'
import { Fade } from "react-awesome-reveal";

const AboutUs = () => {
    const [showMore, setShowMore] = useState(false);

  const toggleText = () => {
    setShowMore(!showMore);
  };
  return (
    <div className='about-us-background' id='about-section'>
        <br/> <br/> <br/> <br/>
        
        <div className="about-us-container">
            <div className="about-us-details">
            <br/> <br/> <br/>
            <Fade cascade damping={0.1}>
      
            <h2>About Us</h2>
            <p>At M2R2, we believe that the sky is not the limit—it’s our canvas. As passionate drone enthusiasts and industry experts, we have made it our mission to redefine what’s possible from above. Here’s why you should choose us:</p>
            <h1> Our Story</h1>
            <p>Founded in 2016
,
M2R2 Drone Services
began as a small team of aerospace engineers and pilots. Our shared vision was to
harness drone technology for the betterment of society
. Over the years, we have
transformed into a dynamic company
, seamlessly integrating cutting-edge technology, creativity, and precision to serve a diverse range of clients. Our collaboration with
Cranfield University’s Autonomous Systems program
has further enriched our industry expertise, making us even more adept in this rapidly advancing field.
            <br/>
            </p>
            </Fade>
            <span id="more" style={{ display: showMore ? "inline" : "none" }}><h2>What Sets Us Apart</h2>
            <p>
                <ol>
                    <li>Expertise and Experience 
                    <ul>
                        <li>Our team includes certified drone pilots, cinematographers, and data analysts. </li>
                        <li>With thousands of flight hours under our belts, we’ve honed our skills in capturing stunning visuals and extracting valuable insights.</li>
                        </ul>
                    </li>
                    <li>Tailored Solutions 
                        <ul>
                            <li>No two projects are alike. We take the time to understand your unique needs and customize our services accordingly.</li>
                            <li>Whether it’s aerial filming, roof inspections, agriculture surveys, or infrastructure assessments, we’ve got you covered.</li>
                        </ul> 
                    </li>
                    <li>Safety First
                        <ul>
                            <li>Safety is our top priority. Our rigorous safety protocols ensure smooth operations and compliance with aviation regulations.</li>
                            <li>We’re fully insured and CAA-approved, giving you peace of mind.</li>
                        </ul>
                    </li>
                    <li>Innovation and Creativity
                        <ul>
                            <li>We thrive on pushing boundaries. From cinematic shots to precision agriculture, we’re always exploring new possibilities.</li>
                            <li>Our state-of-the-art equipment and software keep us at the forefront of the industry. </li>
                        </ul>
                    </li>
                </ol>
            </p>
            <h2>Our Commitment</h2>
            <p>
            At M2R2, we don’t just fly drones—we elevate your vision. Whether it’s capturing breathtaking landscapes, inspecting hard-to-reach areas, or enhancing your business operations, we’re here to make it happen.
            Join us on this aerial journey. Let’s soar together.
            </p>
            </span>
            <div className="read-more-button-container">
            <button id="myBtn" onClick={toggleText}>
                {showMore ? "Read less" : "Read more"}
            </button></div>
            </div>
           
        </div> 
        
    </div>
  )
}

export default AboutUs

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.contact-us-icon {
    position: fixed;
    bottom: 50px;
    right: 50px;
    background-color: #007bff;
    color: #ffffff;
    border-radius: 20px;
    padding: 20px 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    text-decoration: none;
    cursor: pointer;
    z-index: 9999;
    gap:20px;
  }
  .call-button-1 {
    text-decoration: none;
    background-color: transparent;
  }
  
  .contact-us-icon:hover {
    background-color: #0056b3;
  }
  

  @media screen and (max-width: 768px) {
    .contact-us-icon {
      padding: 15px 15px;
      bottom: 50px;
      right: 30px;
      border-radius: 50%;
    }
    .contact-us-text-box{
      display: none;
    }
  }`, "",{"version":3,"sources":["webpack://./src/components/ContactUsIcon/style.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,YAAY;IACZ,WAAW;IACX,yBAAyB;IACzB,cAAc;IACd,mBAAmB;IACnB,kBAAkB;IAClB,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,eAAe;IACf,qBAAqB;IACrB,eAAe;IACf,aAAa;IACb,QAAQ;EACV;EACA;IACE,qBAAqB;IACrB,6BAA6B;EAC/B;;EAEA;IACE,yBAAyB;EAC3B;;;EAGA;IACE;MACE,kBAAkB;MAClB,YAAY;MACZ,WAAW;MACX,kBAAkB;IACpB;IACA;MACE,aAAa;IACf;EACF","sourcesContent":[".contact-us-icon {\n    position: fixed;\n    bottom: 50px;\n    right: 50px;\n    background-color: #007bff;\n    color: #ffffff;\n    border-radius: 20px;\n    padding: 20px 30px;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    font-size: 24px;\n    text-decoration: none;\n    cursor: pointer;\n    z-index: 9999;\n    gap:20px;\n  }\n  .call-button-1 {\n    text-decoration: none;\n    background-color: transparent;\n  }\n  \n  .contact-us-icon:hover {\n    background-color: #0056b3;\n  }\n  \n\n  @media screen and (max-width: 768px) {\n    .contact-us-icon {\n      padding: 15px 15px;\n      bottom: 50px;\n      right: 30px;\n      border-radius: 50%;\n    }\n    .contact-us-text-box{\n      display: none;\n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

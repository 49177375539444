import React from 'react'
import './style.css'
import { Zoom } from "react-awesome-reveal";

const QuickInfoCards = ({id,label,content}) => {
  return (
    <div className='quick-info-item'>
      <Zoom>
        <div className="quick-info-item-header">{id}. {label}
        </div>
        <div className="quick-info-item-description">
        {content}
        </div>
        </Zoom>
    </div>
  )
}

export default QuickInfoCards

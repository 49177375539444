import React, { useState, useEffect } from 'react';
import './style.css';
import { Fade } from "react-awesome-reveal";

const ServiceDescriptionCards = ({title, description, imageAlign,backgroundImage,isBulletPoints}) => {
    const isMobile = window.innerWidth <= 768;
    const [zoomedOut, setZoomedOut] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const imageContainer = document.getElementById('image-container');
            if (imageContainer) {
                const { top } = imageContainer.getBoundingClientRect();
                if (top < window.innerHeight / 2) { // Adjust this value as needed to trigger the animation
                    setZoomedOut(true);
                }
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

  return (
    <div className="card-container">
      {isMobile || imageAlign === "left" ? (
        <>
           <div className="image-description" style={{
                                        width: isMobile ? '100%' : '45%', // Adjust the width to fit the container
                                        height: '250px', // Set a fixed height or adjust dynamically based on content
                                        backgroundImage: `url(${require("../../../assets/" + backgroundImage)})`,
                                        backgroundPosition: 'center',
                                        backgroundRepeat: 'no-repeat',
                                        backgroundSize: 'cover',
                                        overflow: 'hidden', // Ensure the image doesn't overflow the container
                                        aspectRatio: 1 / 1, // Convert hyphenated CSS property to camelCase
                                        transform: zoomedOut ? 'scale(1.2)' : 'scale(1)',
                                        }}></div>
          <div className="service-card-title-container">
            <div className="service-card-title">{title}</div>
            <Fade cascade damping={0.1}>
            {Array.isArray(description) && isBulletPoints ? (
                                <ul>
                                    {description.map((item, index) => (
                                        <li key={index} className="text-info">{item}</li>
                                    ))}
                                </ul>
                            ) : (
                                <div className="text-info">{description}</div>
                            )}
            </Fade>
          </div>
        </>
      ) : (
        <>
          <div className="service-card-title-container">
            <div className="service-card-title">{title}</div>
            <Fade cascade damping={0.1}>
            {Array.isArray(description) && isBulletPoints ? (
                                <ul>
                                    {description.map((item, index) => (
                                        <li key={index} className="text-info">{item}</li>
                                    ))}
                                </ul>
                            ) : (
                                <div className="text-info">{description}</div>
                            )}</Fade>
            </div>
          <div className="image-description" style={{
                                        width: isMobile ? '100%' : '45%', // Adjust the width to fit the container
                                        height: '250px', // Set a fixed height or adjust dynamically based on content
                                        backgroundImage: `url(${require("../../../assets/" + backgroundImage)})`,
                                        backgroundPosition: 'center',
                                        backgroundRepeat: 'no-repeat',
                                        backgroundSize: 'cover',
                                        overflow: 'hidden', // Ensure the image doesn't overflow the container
                                        aspectRatio: 1 / 1, // Convert hyphenated CSS property to camelCase
                                        transform: zoomedOut ? 'scale(1.2)' : 'scale(1)', // Zoom out effect using scale transformation
                                        }}
                                           // Zoom out on hover out
                                        ></div>
        </>
      )}
    </div>
  );
};

export default ServiceDescriptionCards;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  margin: 0;
  padding: 0;
  font-family: 'Nanum Gothic';
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}

@media screen and (max-width: 768px) {
  body {
    font-size: 12px;
    overflow-x: hidden; /* Disable horizontal scrolling on phone screens */
  }
}


code {
  font-family: 'Nanum Gothic';
}

.page-not-found-error-message{
  color: red;
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT,UAAU;EACV,2BAA2B;EAC3B,eAAe;EACf,mCAAmC;EACnC,kCAAkC;;AAEpC;;AAEA;EACE;IACE,eAAe;IACf,kBAAkB,EAAE,kDAAkD;EACxE;AACF;;;AAGA;EACE,2BAA2B;AAC7B;;AAEA;EACE,UAAU;AACZ","sourcesContent":["body {\n  margin: 0;\n  padding: 0;\n  font-family: 'Nanum Gothic';\n  font-size: 16px;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n\n}\n\n@media screen and (max-width: 768px) {\n  body {\n    font-size: 12px;\n    overflow-x: hidden; /* Disable horizontal scrolling on phone screens */\n  }\n}\n\n\ncode {\n  font-family: 'Nanum Gothic';\n}\n\n.page-not-found-error-message{\n  color: red;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

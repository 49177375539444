import React, { useState } from 'react';

import './style.css';
import { Zoom } from "react-awesome-reveal";
import ContactUsForm from '../../ContactUsForm';
const NeedAQuote = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const phoneNumber = '+447464698545'; // Replace with your phone number
  const handleCallButtonClick = () => {
    window.location.href = `tel:${phoneNumber}`;
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const handleGetInTouchClick = () => {
    setIsModalOpen(true);
  };

  const handleModalClick = (e) => {
    if (e.target.classList.contains('modal-overlay')) {
      setIsModalOpen(false);
    }
  };

  return (
    <div className='portfolio-section' id='contactusSection'>
        <br/><br/>
      <div className='main-container'>
        <div className="need-a-quote-container">
            <div className="need-a-quote-action-container">
                <div className="quote-title">Tell Us about Your Project?</div><br/>
                <div className="quote-description">We tailor our services to your unique needs, whether it’s aerial filming, roof inspections, agriculture surveys, or infrastructure assessments, ensuring quality results for any project. Feel free to reach out to us anytime for a free quote.</div>
                <br/><div className="quote-actions">
                    <div className="call-button" onClick={handleGetInTouchClick}>Free Quote</div>
                    <Zoom>
                    <div className="phone-number-section">
                        <div className="phone-icon"></div>
                        
                        <div className="phone-number-title" onClick={handleCallButtonClick}>
                       Call us now</div>
                        
                        
                    </div></Zoom>
                </div>
               
            </div>
            <div className="need-a-quote-image"></div>
        </div><br/><br/>
        {isModalOpen && (
          <div className="modal-overlay" onClick={handleModalClick}>
            <div className="modal-content">
              <ContactUsForm onClose={handleModalClose}/>
            </div>
          </div>
        )}
        <br/><br/><br/>
        <br/><br/><br/>
      
        </div>
    </div>
  )
}

export default NeedAQuote

import React, { useRef,useState } from 'react';
import './style.css';
import ServiceCards from './ServiceCards';

const cardsData = [
    {
      id: 1,
      title: 'Aerial Filming & Photography',
      description: '“Capture the extraordinary with our professional drone photography and videography services.”',
      imageUrl: 'services1.jpg',
      redirectTo:'/aerial-filming_&_photography',
    },
    {
      id: 2,
      title: 'Roof Top Inspection',
      description: '“Elevate your property’s safety and efficiency with our professional drone roof inspection services.”',
      imageUrl: 'services2.jpg',
      redirectTo:'/rooftop-inspection',
    },
    {
      id: 3,
      title: 'Construction Site Inspection',
      description: '“Enhance construction site safety and progress tracking with our professional drone inspection services.”',
      imageUrl: 'services3.jpg',
      redirectTo:'/construction-inspection',
    },
    {
      id: 4,
      title: 'Aerial Survey & Mapping',
      description: '“Unlock precise insights with our professional drone aerial surveying and mapping services.”',
      imageUrl: 'services4.jpeg',
      redirectTo:'/aerial-survey-and-mapping',
    },
    {
      id: 5,
      title: 'Agricultural Services',
      description: '“Optimize your crop management with our precision agriculture drone services.”',
      imageUrl: 'services5.jpg',
      redirectTo:'/agriculture-services',
    },
    {
      id: 6,
      title: 'Industrial Inspections',
      description: '“Ensure industrial safety and compliance with our professional drone inspection services.”',
      imageUrl: 'services6.jpg',
      redirectTo:'/industrial-inspection',
    }, 
];



const ServiceQuickInfo = () => {
  const [currentScroll, setCurrentScroll] = useState(0);

    const containerRef = useRef(null);
  
    const handleScrollRight = () => {
      const cardsContainer = containerRef.current.querySelector('.cards-container');
      const maxScroll = cardsContainer.scrollWidth - cardsContainer.clientWidth;
      const nextScroll = Math.min(currentScroll + 420, maxScroll);
      setCurrentScroll(nextScroll);
      cardsContainer.scrollTo({
        left: nextScroll,
        behavior: 'smooth',
      });
    };
    
    const handleScrollLeft = () => {
      const cardsContainer = containerRef.current.querySelector('.cards-container');
      const nextScroll = Math.max(currentScroll - 420, 0);
      setCurrentScroll(nextScroll);
      cardsContainer.scrollTo({
        left: nextScroll,
        behavior: 'smooth',
      });
    };
  

  return (
    <div className='black-background'>
         <div className='main-container'>

            <div className="card-flex-container">
            <div className="left-align-vertical-text">
                    Our services
                </div>
            <div
      className="service-i-container"
      
      ref={containerRef}
    >
      <div className="arrow left" onClick={() => handleScrollLeft(200) }>
      &gt;
      </div>
      <div className="cards-container">
        {cardsData.map((card) => (
        <ServiceCards
          key={card.id}
          title={card.title}
          description={card.description}
          imageUrl={card.imageUrl}
          redirectTo= {card.redirectTo}
        />
      ))}
      </div>
      <div className="arrow right" onClick={() => handleScrollRight(-200)}>
        &gt;
      </div>
    </div>
                
            </div>

      
        
    </div>   </div>
  )
}

export default ServiceQuickInfo

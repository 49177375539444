import React from 'react'
import './style.css'
import { Fade } from "react-awesome-reveal";


const Demographics = () => {
  return (
    <div id="keyFeaturesSection" className="key-features-layout">
        <div className='blur-container'>
        <div className='main-container'>
                <br/>
                <br/>
                <br/>
                <br/>  <br/>
                <br/>
                <br/>  <br/>
                <br/>
                <br/><br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
            <div className="keyfeatures-container">
                <div className="key-features-section1">
                    <div className="key-feature-section-title">“ Soar Beyond Limits Where Precision Meets the Sky. “</div>
                    <br/>
                    <div className="key-feature-section-divider"></div>
                    <br/>
                    <Fade cascade damping={0.1}>
                    <div className="key-feature-section-description">M2R2 offers end-to-end solutions that complement your infrastructure asset management programme by fusing engineering knowledge, superior drone operations, and specially designed software.</div></Fade>
                </div>  
            </div> <br/>
                <br/>
               
                <br/>
                <br/>
                <br/>
                <br/><br/>
                <br/>
                <br/>
                <br/>
                <br/><br/>
                <br/>
                
    
        </div></div></div>
     )
}

export default Demographics
import React from 'react'
import ServiceDemographics from '../components/ServiceComponents/ServiceDemographics'
import LandingHeaderComponent from '../components/LandingHeaderComponent'
import ServiceDescriptions from '../components/ServiceComponents/ServiceDescriptions'
import QuickInfoBanner from '../components/ServiceComponents/QuickInfoBanner/QuickInfoBanner'
import Portfolio from '../components/ServiceComponents/Portfolio/Portfolio'
import NeedAQuote from '../components/ServiceComponents/NeedAQuote'
import PortfolioData from '../consts/PortfolioData'
import {industrialInspectionDescriptionHeader} from '../consts/ServiceDescriptionHeaders'
import {industrialInspectionServiceDescriptionInfo} from '../consts/ServiceDescriptionInfo'
import ServiceDemographicsInfo from '../consts/serviceDemographicsInfo'
import {IndustrialInspectionQuickInfo} from '../consts/QuickInfoData'
import Footer from '../components/Footer/Footer'


const IndustrialInspectionPage = () => {
  return (
    <div><LandingHeaderComponent/>
    <ServiceDemographics backgroundImage={ServiceDemographicsInfo[4].image} title={ServiceDemographicsInfo[4].title} description={ServiceDemographicsInfo[4].content}/>
    <ServiceDescriptions serviceHeader={industrialInspectionDescriptionHeader} serviceDescription={industrialInspectionServiceDescriptionInfo} isBulletPoints/>
    <QuickInfoBanner QuickInfo={IndustrialInspectionQuickInfo}/>
    <Portfolio portfoliodetails={PortfolioData[4]}/>
    <NeedAQuote/>
    <Footer/>
    </div>
  )
}

export default IndustrialInspectionPage

import React from 'react'
import { FaPhone } from "react-icons/fa";
import './style.css'

const ContactUsIcon = () => {
    const phoneNumber = '+447464698545'; // Replace with your phone number
    const handleCallButtonClick = () => {
        window.location.href = `tel:${phoneNumber}`;
      };
  return (
    <div className="contact-us-icon" onClick={handleCallButtonClick}>
      <FaPhone /> <div className='contact-us-text-box'>   Get instant quote</div>
    </div>
  )
}

export default ContactUsIcon

import React from 'react'
import './style.css'
import ServiceDescriptionCards from "./ServiceDescriptionCards"

const ServiceDescriptions = ({serviceHeader, serviceDescription, isBulletPoints}) => {
  return (
    <div className="service-description-block">
    <div className='main-container'>
            <br/><br/><br/>
            <br/><br/><br/>
            <div className="component-centralised-titles">
                <div className="service-description-title">{serviceHeader.title}</div>
                <br/><div className="service-description-tagline">{serviceHeader.description}</div>
            </div>
            
            {serviceHeader.subTitle !== null && serviceHeader.subTitle !== '' ? (
              <> <br/><br/>
              <div className="component-centralised-titles">
                <div className="service-description-title">{serviceHeader.subTitle}</div>
              </div></>
            ) : null}
            <br/>
            <br/>
    <div className='service-desc-cards-container'>
          {serviceDescription.map((service, index) => (
              <ServiceDescriptionCards
                key={index}
                title={service.title}
                description={service.description}
                backgroundImage={service.image}
                isBulletPoints={isBulletPoints} // Pass isBulletPoints to ServiceDescriptionCards
                imageAlign={index % 2 === 0 ? "left" : "right"} // Alternate the alignment based on index
              />
            ))}
    </div>
    </div></div>
  )
}

export default ServiceDescriptions

import React from 'react'
import LandingHeaderComponent from '../components/LandingHeaderComponent'
import Demographics from '../components/Demographics/Demographics'
import ServiceQuickInfo from '../components/LandingPageComponents/ServiceQuickInfo'
import PortfolioData from '../consts/PortfolioData'
import Portfolio from '../components/ServiceComponents/Portfolio/Portfolio'
import Footer from '../components/Footer/Footer'
import AboutUs from '../components/LandingPageComponents/AboutUs'
import NeedAQuote from '../components/ServiceComponents/NeedAQuote'

const LandingPage = () => {
  return (
    <div className='landing-body-bg'>
    <LandingHeaderComponent/>

    <Demographics/>
    <div>
      <ServiceQuickInfo/>
      <AboutUs/>
      <br/><br/><br/>
    </div>
    <Portfolio portfoliodetails={PortfolioData[6]}/>
    <NeedAQuote/>
    <Footer/>
    </div>
  )
}

export default LandingPage
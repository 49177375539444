import React from 'react'
import ServiceDemographics from '../components/ServiceComponents/ServiceDemographics'
import LandingHeaderComponent from '../components/LandingHeaderComponent'
import ServiceDescriptions from '../components/ServiceComponents/ServiceDescriptions'
import Portfolio from '../components/ServiceComponents/Portfolio/Portfolio'
import NeedAQuote from '../components/ServiceComponents/NeedAQuote'
import PortfolioData from '../consts/PortfolioData'
import {roofTopInspectionDescriptionHeader} from '../consts/ServiceDescriptionHeaders'
import {RoofTopServiceDescriptionInfo} from '../consts/ServiceDescriptionInfo'
import ServiceDemographicsInfo from '../consts/serviceDemographicsInfo'
import { RoofTopInspectionQuickInfo } from '../consts/QuickInfoData'
import QuickInfoBanner from '../components/ServiceComponents/QuickInfoBanner/QuickInfoBanner'
import Footer from '../components/Footer/Footer'
// import {RoofTopInspection} from '../consts/PricingDetailsData'
// import Pricing from '../components/ServiceComponents/Pricing'

const RoofTopInspectionPage = () => {
  return (
    <div><LandingHeaderComponent/>
    <ServiceDemographics backgroundImage={ServiceDemographicsInfo[5].image} title={ServiceDemographicsInfo[5].title} description={ServiceDemographicsInfo[5].content}/>
    <ServiceDescriptions serviceHeader={roofTopInspectionDescriptionHeader} serviceDescription={RoofTopServiceDescriptionInfo}/>
    <QuickInfoBanner QuickInfo={RoofTopInspectionQuickInfo}/>
    <Portfolio portfoliodetails={PortfolioData[5]}/>
    {/* <Pricing pricingDetails={RoofTopInspection}/> */}
    <NeedAQuote/>
    <Footer/>
    </div>
  )
}

export default RoofTopInspectionPage

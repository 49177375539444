// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.about-us-background{
    width: 100%;
}

.about-us-container{
    width: 70%;
    color: black;
    z-index: 8;
    background-color: rgba(255, 255, 255, 0.9);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
   
}

.about-us-details{
    width: 80%;
    margin: auto;
    text-align: left;
    z-index: 10;
    font-weight: 300;
}

#myBtn{
    background-color: black;
    color: white;
    border-radius: 5px;
    padding: 5px 20px;
    cursor: pointer;
}

#myBtn:hover{
    background-color: white;
    color: black;
    border-radius: 5px;
    padding: 5px 20px;
    border: solid 1px black;
}

.read-more-button-container{
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
    height: 70px;
}

@media screen and (max-width: 768px) {
    .about-us-container{
        width: 90%;
    }
}

`, "",{"version":3,"sources":["webpack://./src/components/LandingPageComponents/AboutUs/style.css"],"names":[],"mappings":"AAAA;IACI,WAAW;AACf;;AAEA;IACI,UAAU;IACV,YAAY;IACZ,UAAU;IACV,0CAA0C;IAC1C,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,iBAAiB;IACjB,kBAAkB;;AAEtB;;AAEA;IACI,UAAU;IACV,YAAY;IACZ,gBAAgB;IAChB,WAAW;IACX,gBAAgB;AACpB;;AAEA;IACI,uBAAuB;IACvB,YAAY;IACZ,kBAAkB;IAClB,iBAAiB;IACjB,eAAe;AACnB;;AAEA;IACI,uBAAuB;IACvB,YAAY;IACZ,kBAAkB;IAClB,iBAAiB;IACjB,uBAAuB;AAC3B;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,YAAY;IACZ,YAAY;AAChB;;AAEA;IACI;QACI,UAAU;IACd;AACJ","sourcesContent":[".about-us-background{\n    width: 100%;\n}\n\n.about-us-container{\n    width: 70%;\n    color: black;\n    z-index: 8;\n    background-color: rgba(255, 255, 255, 0.9);\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    margin-left: auto;\n    margin-right: auto;\n   \n}\n\n.about-us-details{\n    width: 80%;\n    margin: auto;\n    text-align: left;\n    z-index: 10;\n    font-weight: 300;\n}\n\n#myBtn{\n    background-color: black;\n    color: white;\n    border-radius: 5px;\n    padding: 5px 20px;\n    cursor: pointer;\n}\n\n#myBtn:hover{\n    background-color: white;\n    color: black;\n    border-radius: 5px;\n    padding: 5px 20px;\n    border: solid 1px black;\n}\n\n.read-more-button-container{\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    margin: auto;\n    height: 70px;\n}\n\n@media screen and (max-width: 768px) {\n    .about-us-container{\n        width: 90%;\n    }\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

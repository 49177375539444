import React from 'react'
import { useNavigate } from "react-router-dom";

const PageNotFound = () => {
    const navigate = useNavigate();

  return (
    <div className="about-us-details" style={{ margin: '40vh auto 0', textAlign: 'center' }}>
    <h5>404 Error!</h5>
    <h3 className="page-not-found-error-message">Page Not found</h3>
    <h4>Sorry! The page you are looking for could not be found or has been removed</h4>
    <br/>
    <div className="myBtn"  style={{ cursor: 'pointer' }} onClick={() => { navigate("/"); }}>Click here to go back to <span style={{ color: 'blue' }}>Home page</span></div>
  </div>
  )
}

export default PageNotFound

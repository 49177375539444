
const AerialServiceDescriptionInfo = [
    {
        title: "Property / Estate Agents",
        description:
        "Showcase properties in their best light with our drone filming and photography services tailored for real estate. Our aerial capabilities provide potential buyers with an immersive experience, offering a bird’s-eye view of estates, landscapes, and neighbourhoods. Highlight architectural details, sweeping grounds, and the surrounding area with stunning clarity and precision. Elevate your property listings and attract more buyers with captivating visuals that tell a compelling story of each space.",
        image:"aerial-service-2.jpg",
    },
    {
        title: "Events",
        description:
        "Capture the excitement of your events and activities from a spectacular vantage point with our drone filming and photography services. Our drones soar above the crowd, offering breathtaking aerial snapshots and videos that ensure your special moments are remembered forever. Perfect for outdoor adventures, festivals, and corporate events, our services provide a unique way to document the energy and scale of your gatherings. Let’s make your events unforgettable - from the sky!",
        image:"aerial-service-1.jpg",
        
    },
   
    {
        title: "Aerial 360º Photography",
        description:
        "Step into a world without borders with our 360-degree panorama drone photography services. Our drones capture a seamless, all-encompassing view, providing an immersive experience that feels as real as being there. deal for immersive property tours, dynamic event coverage, and engaging promotional content, our panoramic images provide a captivating experience from every perspective. Connect with us for a truly boundless perspective.",
        image:"aerial-service-3.jpg",
    },
    {
        title: "Marketing",
        description:
        "Elevate your brand to new heights with our  drone filming and photography services. Our cutting-edge technology and creative vision bring a fresh perspective to your advertising needs. Captivate your audience with stunning aerial views that showcase the essence of your brand. Whether it's a panoramic skyline or a detailed close-up, our high-resolution images and smooth cinematic footage will set you apart from the competition. Connect with us today and let's take your brand's visual storytelling to the next level!",
        image:"aerial-service-4.jpg",
    },
  ];

const constrcutionServiceDescriptionInfo = [
    { 
     title: "Rapid Deployment", 
     description: 
     "Drones have revolutionized construction site inspections with their swift and effortless deployment capabilities. Unlike traditional methods that often require extensive preparation and setup, drones can be airborne within minutes, providing immediate aerial perspectives and data collection. This rapid response not only saves valuable time but also enhances the efficiency of the inspection process, making drones an indispensable tool for modern construction site management.", 
    image:"construction-service-1.jpg", 
    }, 
    { 
     title: "Comprehensive Coverage", 
     description: 
    "Drones offer an unmatched level of comprehensive coverage for construction site inspections, making them an ideal choice for project managers and site supervisors. With the ability to effortlessly soar over challenging terrain and capture detailed aerial imagery, drones provide a bird’s-eye view that manual inspections can’t achieve. This extensive reach ensures that every inch of a construction site is thoroughly examined, leading to better-informed decisions and a more streamlined construction process.", 
     image:"construction-service-2.jpg", 
    }, 
    { 
     title: "Minimize Rework and Enhance Safety", 
    description: 
     "The integration of drones into construction site inspections is a game-changer, particularly for minimizing rework and enhancing safety. These agile devices can quickly identify discrepancies and potential issues, allowing for immediate corrective action before they escalate into costly reworks. Moreover, drones eliminate the need for personnel to navigate hazardous areas, significantly reducing the risk of workplace accidents and promoting a safer construction environment. This dual benefit of drones underscores their value as an essential tool in modern construction practices.", 
      image:"construction-service-3.jpg", 
   }, 
   { 
    title: "Real-Time Feedback", 
    description: 
    "Drones are perfectly suited for construction site inspections due to their ability to provide real-time feedback, a critical advantage in the fast-paced construction industry. With live-streaming capabilities, drones offer immediate visual data to project managers, enabling quick assessments and decisions. This instant communication loop ensures that any necessary adjustments can be made on the fly, greatly reducing delays and improving the overall efficiency of the construction process.",
    image:"construction-service-4.jpg", 
    }, 
];

const agricultureServiceDescriptionInfo = [
    { 
    title: "Advanced Crop Monitoring",  
    description: 
    "Our drones are the eyes in the sky that never blink. Equipped with state-of-the-art sensors, they scan your fields to provide vital information on plant health, hydration levels, and nutrient deficiencies. This real-time data allows for timely interventions, ensuring your crops are always at their best.", 
        image:"agri-service-1.jpg", 
    }, 
    { 
        title: "Efficient Spraying Techniques", 
        description: 
        "Say goodbye to wasteful and uneven spraying. Our drones deliver targeted applications of fertilizers, pesticides, and herbicides, directly where they’re needed. This not only conserves resources but also protects the surrounding ecosystem from potential runoff.", 
        image:"agri-service-2.jpg", 
    }, 
    { 
    title: "Customized Flight Plans", 
    description: 
        "Every field is unique, and so are our flight plans. Tailored to the contours of your land, our drones cover every inch with precision. Whether it’s a small family farm or a large agricultural enterprise, our service scales to your needs.", 
    image:"agri-service-3.jpg", 
    }, 
    { 
    title: "Data-Driven Decisions", 
    description: 
        "Make informed decisions with our comprehensive analytics dashboard. Visualize your farm’s health, track changes over time, and plan for the future with confidence. Our insights empower you to maximize yield and profitability. ",
        image:"agri-service-4.jpg"
    }, 
    {
    title: "Environmentally Conscious Farming", 
    description: 
        "Our commitment to the environment is unwavering. By reducing the need for ground vehicles, we lower carbon emissions and soil disruption. Our drones help you maintain an ecological balance while achieving agricultural excellence. ",
        image:"agri-service-5.jpg", 
    }, 
];

const aerialSurveyingDescriptionInfo = [
    { 
title: " Detailed Insights from Above", 
  description: 
  " Our aerial surveying services offer a bird's-eye view of your project area, providing detailed topographic data, vegetation analysis, and site surveillance. With our high-quality imagery, you can gain insights into land features and changes over time, enabling better planning and decision-making.", 
     image:"mapping-service-1.jpg", 
  }, 
  { 
  title: " Efficient Mapping for Any Project", 
   description: 
  " Whether you're monitoring construction progress, conducting environmental assessments, or planning urban development, our mapping services provide the precision and scale you need. We deliver orthomosaic maps, 3D models, and volumetric measurements that are essential for accurate project execution.", 
   image:"mapping-service-2.jpg", 
  }, 
   { 
     title: " Tailored Solutions for Your Needs", 
      description: 
    " We understand that every project is unique. That's why we offer customized solutions that are tailored to meet your specific requirements. Our team works closely with you to ensure that our aerial surveying and mapping services align with your goals and deliver the results you expect.", 
     image:"mapping-service-3.webp", 
      }, 
      {   
     title: " Data You Can Trust", 
      description: 
        "Accuracy is at the heart of everything we do. Our data processing techniques ensure that you receive reliable and actionable information. With M2R2, you can make confident decisions based on data you can trust.",
        image:"mapping-service-4.jpg", 
        }, 
];

const RoofTopServiceDescriptionInfo = [
    {
        title: "Residential Drone Roof Inspections",
        description:
        "Our Residential Drone Roof Inspections service is the perfect solution for homeowners seeking a detailed assessment of their roof without the hassle and risk of traditional methods. Our drones, equipped with the latest in camera technology, provide a comprehensive view of your roof’s condition, identifying potential issues with unparalleled accuracy. Enjoy the convenience of a thorough inspection from the comfort of your ground, and gain the insights you need to ensure your home remains safe and sound.",
        image:"roof-service-1.jpg",
        
    },
    {
        title: "Commercial Drone Roof Inspections",
        description:
        "Take your business to new heights with our Commercial Drone Roof Inspections service. Our drones, equipped with advanced imaging systems, provide a detailed aerial perspective of your commercial property, ensuring that every inch of your roof is meticulously examined. From identifying potential leak points to assessing wear and tear, our service offers a comprehensive evaluation that is both time-saving and cost-effective. Trust us to deliver high-resolution insights that empower you to make informed decisions about roof maintenance and repairs, keeping your operations smooth and uninterrupted.",
        image:"roof-service-2.jpg",
    },
    {
        title: "Customized Reports",
        description:
        "At M2R2, we understand that every roof has its unique challenges. That’s why we offer customised Drone Roof Inspections services tailored to meet the specific needs of your property. Whether it’s a complex commercial structure or a quaint residential home, our drones are equipped with the latest technology to provide detailed inspections and actionable insights. With our service, you’ll benefit from a personalized approach that ensures thorough coverage and precise analysis, giving you the confidence to make the best decisions for your roof’s maintenance and repair.",
        image:"roof-service-3.jpg",
    },
    
  ];

  const industrialInspectionServiceDescriptionInfo = [
    {
        title: "Wind Turbine Inspections",
        description:["Blade Analysis: Detect structural anomalies, wear, and tear with high-resolution imagery.",
                     "Gearbox Inspection: Utilize thermal imaging to identify overheating components before they fail.", 
                     "Foundation Assessment: Evaluate the integrity of wind turbine foundations with detailed aerial surveys."],
        image:"industrial-service-1.jpg",
        
    },
    {
        title: "Power line Inspections",
        description:["Vegetation Management: Prevent outages with precise mapping of vegetation encroachment.",
                    "Component Evaluation: Identify potential issues in insulators, conductors, and towers with detailed visual inspections.",
                    "Thermal Imaging: Spot electrical faults and hotspots that indicate maintenance needs."],
        image:"industrial-service-2.jpg",
    },
    {
        title: "Solar Panel Inspections",
        description:["Panel Health Checks: Assess solar panel conditions with thermal and visual imaging to maximize energy output.",
                    "Site Surveys: Plan and optimize solar farm layouts with accurate topographical data.",
                    "Maintenance Scheduling: Use predictive analysis to schedule maintenance and reduce downtime"],
        image:"industrial-service-3.jpg",
    },
    
  ];

export { AerialServiceDescriptionInfo, constrcutionServiceDescriptionInfo , agricultureServiceDescriptionInfo,aerialSurveyingDescriptionInfo,RoofTopServiceDescriptionInfo,industrialInspectionServiceDescriptionInfo};
export default AerialServiceDescriptionInfo;  
     
   

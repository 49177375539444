import React from 'react'
import './style.css'
import { ReactTyped } from "react-typed";
import { Fade } from "react-awesome-reveal";



const ServiceDemographics = ({backgroundImage, title, description}) => {
  const descriptionContent = description;
  return (
    <div className="key-features-layout"
      style={{
        width: '100%',
        height: '750px',
        zIndex: -1,
        backgroundImage: `url(${require("../../../assets/" + backgroundImage)})`,
        backgroundAttachment: 'fixed',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        backgroundSize: 'cover',
      }}
    ><div className='blur-container'>
        <div className='main-container'>
            <br/><br/><br/> <br/><br/><br/><br/><br/><br/><br/>
            <div className="component-centralised-titles">
              <div className ="mobile-view-spacer"></div>
              <br/>
              <Fade>
                <div className="demographics-title">{title}</div></Fade>
                <br/><br/><br/><br/>
                <div className="demographics-desc">
                <ReactTyped
                  strings={[
                    descriptionContent,
                    descriptionContent,
                  ]}
                  typeSpeed={50}
                  backSpeed={140}
                  loop
                />
                  </div>
            </div>


        </div>

      {/* Content of the component */}
    </div> </div>
  )
}

export default ServiceDemographics

import React from 'react'
import LandingHeaderComponent from '../components/LandingHeaderComponent'
import ServiceDemographics from '../components/ServiceComponents/ServiceDemographics'
import ServiceDescriptions from '../components/ServiceComponents/ServiceDescriptions'
import Portfolio from '../components/ServiceComponents/Portfolio/Portfolio'
import NeedAQuote from '../components/ServiceComponents/NeedAQuote'
import ServiceDemographicsInfo from '../consts/serviceDemographicsInfo'
import { constrcutionServiceDescriptionInfo } from '../consts/ServiceDescriptionInfo'
import { ConstructionServiceDescriptionHeader } from '../consts/ServiceDescriptionHeaders'
import PortfolioData from '../consts/PortfolioData'
import Footer from '../components/Footer/Footer'

const ConstructionInspectionPage = () => {

  return (
    <div><LandingHeaderComponent/>
    <ServiceDemographics backgroundImage={ServiceDemographicsInfo[1].image} title={ServiceDemographicsInfo[1].title} description={ServiceDemographicsInfo[1].content}/>
    <ServiceDescriptions serviceHeader={ConstructionServiceDescriptionHeader} serviceDescription={constrcutionServiceDescriptionInfo}/>
    <Portfolio portfoliodetails={PortfolioData[2]}/>
    <NeedAQuote/>
    <Footer/>
    </div>

  )
}

export default ConstructionInspectionPage

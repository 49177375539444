import React from 'react';
import './style.css';


const Portfolio = ({portfoliodetails}) => {
  return (
    <div className='portfolio-section' id='portfolioSection'>
      <div className='main-container'>
        <br/><br/><br/>
        <br/><br/><br/>
        <div className="component-centralised-titles">
                <div className="service-description-title">{portfoliodetails.header}</div>
                <br/><div className="service-description-tagline">{portfoliodetails.description}</div>
            </div><br/><br/><br/>
        <div className="image-grid">
          {portfoliodetails.imageFilenames.map((imageName, index) => (
            <img
              key={index}
              src={require(`../../../assets/portfolios/${portfoliodetails.portfolioName}/${imageName}`)}
              alt={imageName}
              className="image-item"
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Portfolio;